import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"

const WhatsApp = () => (
    <a href="https://wa.me/524443408733?text=Servicios especializados para nómina fija, nómina variable y servicios financieros"
       className="whatsapp"
       target="_blank">
        <FontAwesomeIcon icon={faWhatsapp}/>
    </a>

);

export default WhatsApp;
