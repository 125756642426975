import React from 'react';
import {Link} from 'gatsby';

export default function Nav({
                                onClose = () => {
                                }
                            }) {
    return (
        <nav id="menu">
            <div className="inner">
                <h2>Menu</h2>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Services">Servicios</Link>
                    </li>
                    <li>
                        <Link to="/Prices">Precios</Link>
                    </li>
                    <li>
                        <Link to="/Policies">Política de calidad</Link>
                    </li>
                    <li>
                        <Link to="/News">Noticias</Link>
                    </li>
                    <li>
                        <Link to="/Contact">Contacto</Link>
                    </li>
                </ul>
            </div>
            <a
                className="close"
                onClick={e => {
                    e.preventDefault();
                    onClose();
                }}
                href="#menu"
            >
                Close
            </a>
        </nav>
    );
}
